<template>
  <div>
    <b-card title="资产基本信息">
      <b-row>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              资产名称：
            </div>
            <div class="desc-text">
              {{ assestInfo.name }}
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              资产类型：
            </div>
            <div class="desc-text">
              {{ assestInfo.assetsTypeName }}
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              资产负责人：
            </div>
            <div class="desc-text">
              {{ assestInfo.responsiblePerson }}
              <span>{{ assestInfo.responsiblePersonPhone }}</span>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              安全负责人：
            </div>
            <div class="desc-text">
              {{ assestInfo.safetyUser }}
              <span>{{ assestInfo.safetyUserPhone }}</span>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              所属部门：
            </div>
            <div class="desc-text">
              <el-tag
                v-for="(item,index) in assestInfo.deptList"
                :key="index"
                class="mr10"
              >
                {{ item.deptName }}
              </el-tag>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              归属区域：
            </div>
            <div class="desc-text">
              {{ assestInfo.belongingArea ? belongingAreaObj[assestInfo.belongingArea] : '' }}
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              物理位置：
            </div>
            <div class="desc-text">
              {{ assestInfo.geographicLocation }}
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              资产环境：
            </div>
            <div class="desc-text">
              {{ assestInfo.environment ? environmentObj[assestInfo.environment] : '' }}
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              IP地址/端口：
            </div>
            <div class="desc-text">
              {{ assestInfo.ipAddress }}<span v-if="assestInfo.port">:{{ assestInfo.port }}</span>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              操作系统：
            </div>
            <div class="desc-text">
              {{ assestInfo.osName }}
            </div>
          </div>
        </b-col>
        <b-col
          v-if="assestInfo.assetsTypeParent === 3"
          md="4"
        >
          <div class="desc-wrap">
            <div class="desc-label">
              数据库类型：
            </div>
            <div class="desc-text">
              {{ assestInfo.dbType }}
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              描述：
            </div>
            <div class="desc-text">
              {{ assestInfo.description }}
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="desc-wrap">
            <div class="desc-label">
              安全保护措施：
            </div>
            <div class="desc-text">
              {{ assestInfo.securityMeasures }}
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <div class="desc-wrap">
            <div class="desc-label">
              涉及业务：
            </div>
            <div class="desc-text">
              <el-tag
                v-for="(item,index) in assestInfo.productList"
                :key="index"
                class="mr10"
              >
                {{ item.name }}
              </el-tag>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="个人信息处理">
      <el-table
        border
        stripe
        :data="assestInfo.dmAssetsData"
      >
        <el-table-column
          prop="productName"
          :show-overflow-tooltip="true"
          label="业务名称"
        />
        <el-table-column
          prop="stage"
          :show-overflow-tooltip="true"
          label="处理阶段"
        />
        <el-table-column
          prop="description"
          :show-overflow-tooltip="true"
          label="用途"
        />
        <el-table-column
          prop="fieldNames"
          :show-overflow-tooltip="true"
          label="隐私字段"
        />
        <el-table-column
          prop="fieldDataCount"
          :show-overflow-tooltip="true"
          label="数据量"
        />
      </el-table>
      <div
        class="dialog-footer text-center mt20"
      >
        <el-button @click="back">
          返 回
        </el-button>
      </div>
    </b-card>

  </div>

</template>

<script>
// eslint-disable-next-line import/named
import { getAssetsById } from '@/api/enterprise/assest'

export default {
  name: 'ProductDetail',
  data() {
    return {
      loading: false,
      belongingAreaObj: {
        1: '可信任域',
        2: '不可信任域',
        3: '未知',
      },
      environmentObj: {
        1: '企业本地',
        2: '公有云',
        3: '用户环境',
        4: '其他',
      },
      assestInfo: {
        id: '',
        ResponsiblePersonPhone: '',
        assetsTypeName: '',
        name: '',
        safetyUser: '',
        safetyUserPhone: '',
        geographicLocation: '',
        belongingArea: '',
        dmAssetsData: [],
      },
    }
  },
  created() {
    this.getAssetsById()
  },
  mounted() {
    this.navActiveInit(1, 'IT资产管理', '数据智能')
  },
  destroyed() {
    this.navActiveInit(2, 'IT资产管理', '数据智能', '/asset')
  },
  methods: {
    back() {
      sessionStorage.setItem('activeName', '2')
      this.$router.push({
        path: '/asset',
      })
    },
    getAssetsById() {
      this.loading = true
      getAssetsById({ id: Number(this.$route.query.id) }).then(res => {
        const resData = res.data
        this.loading = false
        if (resData.code === 0) {
          this.assestInfo = resData.data
        }
      }).catch(e => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
  .assest-detail-wrap{
     .el-descriptions__header{
      margin-bottom: 10px;
    }
    .el-descriptions :not(.is-bordered) .el-descriptions-item__cell{
      padding-bottom: 5px;
    }
    .el-divider--horizontal{
      margin: 10px 0;
    }
    .datainfo-item{
      padding: 10px;
      border: 1px solid #DCDFE6;
    }
    .el-descriptions-item__container .el-descriptions-item__content{
      font-size: 12px;
    }
  }

</style>
